import { Controller } from 'stimulus'

// Controller for adding a type="radio" like functionality for radios (not necessarily belong to the same group)
// and checkboxes
export default class extends Controller {
  static targets = ['radioButton', 'styleButton']

  connect() {
    this.radioButtonTargets.forEach((radioButton) => {
      radioButton.addEventListener('change', this.handleChanges)
      console.log(radioButton.checked);
    })
    this.initialClasses = this.styleButtonTargets.map(
      (styleButton) => styleButton.classList.value
    )
    this.initialState = {
      styledButtons: this.styleButtonTargets.map(
        (styleButton) => styleButton.classList.value
      ),
      inputValues: this.radioButtonTargets.map((input) => input.checked),
    }
  }

  handleChanges = ({ currentTarget }) => {
    this.uncheckOtherRadioButtons(currentTarget)
    this.handleButtonStyles(currentTarget)
  }

  uncheckOtherRadioButtons = (currentRadioButton) => {
    this.radioButtonTargets.forEach((radioButton, i) => {
      if (radioButton == currentRadioButton) return
      if (!currentRadioButton.checked) {
        radioButton.checked = this.initialState.inputValues[i]
      } else if (radioButton.checked) {
        radioButton.checked = false
      }
    })
  }

  handleButtonStyles(currentRadioButton) {
    if (currentRadioButton.checked) {
      this.styleButtonTargets.forEach((element) => {
        element.classList.remove('active')
      })
    } else {
      this.styleButtonTargets.forEach((styleButton, i) => {
        styleButton.classList = this.initialState.styledButtons[i]
      })
    }
  }
}
