import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['show']

  display() {
    if (this.isShowTargetHidden()) {
      this.showElement()
    } else {
      this.hideElement()
    }
  }

  hide() {
    if (!this.isShowTargetHidden()) this.hideElement()
  }

  showElement() {
    this.element.classList.add('active')
    this.showTarget.classList.remove('hidden')
  }
  hideElement() {
    this.element.classList.remove('active')
    this.showTarget.classList.add('hidden')
  }

  isShowTargetHidden() {
    return this.showTarget.classList.contains('hidden')
  }
}
