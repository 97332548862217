import PlacesAutocomplete from 'stimulus-places-autocomplete'
import { Loader } from '@googlemaps/js-api-loader'

export default class extends PlacesAutocomplete {
  static targets = super.targets.concat(['streetAddress', 'subpremise'])
  static values = { apiKey: String }

  // connect overridden to import the "places" library with @googlemaps/js-api-loader
  async connect() {
    if (!this.hasApiKeyValue) return

    const loader = new Loader({ apiKey: this.apiKeyValue, version: 'weekly' })
    this.Places = await loader.importLibrary('places')
    this.initAutocomplete()
  }

  setAddressComponents(address) {
    super.setAddressComponents(address)
    if (this.hasStreetAddressTarget)
      this.streetAddressTarget.value = this.setStreetAddress(address)
    if (this.hasSubpremiseTarget) this.subpremiseTarget.value = address.subpremise || ''
  }

  // set the Autocomplete options in this getter.
  get autocompleteOptions() {
    return {
      fields: ['address_components'],
      componentRestrictions: {
        country: this.countryValue,
      },
    }
  }

  // initAutocomplete is overridden so it uses the imported "places" library
  initAutocomplete() {
    if (!this.hasAddressTarget) return

    this.autocomplete = new this.Places.Autocomplete(
      this.addressTarget,
      this.autocompleteOptions
    )
    this.autocomplete.addListener('place_changed', this.placeChanged)
  }

  formatAddressComponents(addressComponents) {
    const data = {}

    addressComponents.forEach((component) => {
      const type = component.types[0]

      data[type] = type == 'country' ? component.short_name : component.long_name
    })

    return data
  }

  setStreetAddress(address) {
    if (!address.street_number) {
      return address.route || ''
    } else {
      return `${address.street_number} ${address.route}`
    }
  }
}
